@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
body {
  margin: 0;
  line-height: normal;
}
html {
  font-family: Roboto, Montserrat, Poppins, Helvetica, sans-serif;
}
:root {
  /* fonts */
  /* --regular: Roboto;
  --font-public-sans: Roboto;
  --typography-body-1: Roboto;
  --font-heebo: Roboto; */

  /* font sizes */
  --regular-size: 14px;
  --regular1-size: 12px;
  --font-size-xl: 20px;
  --typography-body-1-size: 16px;
  --components-button-large-size: 15px;
  --font-size-15xl: 34px;
  --components-button-small-size: 13px;

  /* Colors */
  --light-info-contrast: #fff;
  --black-100: #1c1c1c;
  --color-gray-200: rgba(0, 0, 0, 0);
  --light-text-primary: rgba(0, 0, 0, 0.87);
  --light-other-filled-input-background: rgba(0, 0, 0, 0.06);
  --light-text-secondary: rgba(0, 0, 0, 0.6);
  --color-gray: rgba(255, 255, 255, 0);
  --color-gray-100: rgba(0, 0, 0, 0.2);
  --black-5: rgba(0, 0, 0, 0.05);
  --primary-blue: #e3f5ff;
  --color-lavender: #e5e6fc;
  --color-black: #000;
  --color-darkslateblue: #004a98;
  --color-darkslategray: #2f2f2f;
  --light-primary-main: #1976d2;

  /* Gaps */
  --gap-5xs: 8px;
  --gap-21xl: 40px;
  --gap-5xl: 24px;
  --gap-11xl: 30px;
  --gap-10xs: 3px;
  --gap-11xs: 2px;

  /* Paddings */
  --padding-5xs: 8px;
  --padding-9xs: 4px;
  --padding-3xs: 10px;
  --padding-xs: 12px;
  --padding-base: 16px;
  --padding-7xs: 6px;
  --padding-3xl: 22px;
  --padding-sm: 14px;
  --padding-10xs: 3px;

  /* border radiuses */
  --br-5xs: 8px;
  --br-9xs: 4px;
  --br-base: 16px;

  /* Effects */
  --elevation-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
}
/* Custom CSS */
:where(.css-dev-only-do-not-override-19gw05y).ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  background-color: #1976d2 !important;
}
.ant-space-item {
  font-family: Roboto;
  color: white !important;
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-input-affix-wrapper {
  position: relative;
  display: inline-flex;
  width: 360px;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  background-color: #004a98 !important;
  background-image: none;
  border-width: 1.2px !important;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-input-affix-wrapper
  > input.ant-input {
  padding: 1px;
  font-size: 14px;
  border: none;
  border-radius: 0;
  outline: none;
  background-color: #004a98 !important;
  color: white;
  font-family: Roboto;
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: 400;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-input-affix-wrapper
  .ant-input-prefix {
  margin-inline-end: 4px;
  color: white;
}
input::placeholder {
  color: white !important;
}
/* .ant-table-tbody .ant-table-row:nth-child(even) {
  background-color: #b05050 !important;
} */

/* .ant-table-tbody .ant-table-cell:nth-child(even) {
  background-color: #b05050 !important ;
} */

.ant-table-tbody .ant-table-row:nth-child(odd) {
  background-color: #fafbfc; /* Set your desired background color for alternate rows */
}
/* .ant-table-tbody > tr.ant-table-row-level-1:hover > td {
  background: red;
} */
:where(.css-dev-only-do-not-override-19gw05y).ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-row:hover
  > th,
:where(.css-dev-only-do-not-override-19gw05y).ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td,
:where(.css-dev-only-do-not-override-19gw05y).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th.ant-table-cell-row-hover,
:where(.css-dev-only-do-not-override-19gw05y).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td.ant-table-cell-row-hover {
  background: #e5edf5;
}
.ant-table-thead .ant-table-cell {
  background-color: #f5f7f9 !important;
}

:where(.css-dev-only-do-not-override-19gw05y).ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled
  ):hover {
  border-color: white !important;
  border-width: 2px !important;
  z-index: 1;
}
span.anticon.anticon-close-circle {
  color: white !important;
  font-size: 15px !important;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-19gw05y).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 500;
  text-align: start;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;
  font-size: 14px;
  line-height: 145%;
  font-family: Roboto;
  letter-spacing: 0.5px;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  border-color: white !important;
  border-width: 2px !important;
}
.letter-spacing {
  letter-spacing: 0.5px;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-table-wrapper
  .ant-table-column-sorters {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: left !important;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-table-wrapper
  .ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: none !important;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-btn {
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  /* background-color: transparent;
  border: 1px solid transparent; */
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714285714;
  color: rgba(0, 0, 0, 0.88);
  width: 345px;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-carousel
  .slick-dots
  li
  button {
  position: relative;
  display: block;
  width: 100%;
  height: 10px;
  padding: 0;
  color: black;
  font-size: 0;
  background: #d7e0e9;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.3s;
  border-radius: 50%;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-carousel
  .slick-dots
  li.slick-active
  button {
  background: #336ead;
  opacity: 1;
  height: 10px;
  border-radius: 5px;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-carousel
  .slick-initialized
  .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  position: relative;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: white;
}

:where(.css-dev-only-do-not-override-19gw05y).ant-select .ant-select-arrow {
  display: flex;
  align-items: center;
  color: white;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  inset-inline-start: auto;
  inset-inline-end: 11px;
  height: 12px;
  margin-top: -6px;
  font-size: 12px;
  pointer-events: none;
}

:where(.css-dev-only-do-not-override-19gw05y).ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: white !important;
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Roboto;
  position: relative;
  display: inline-block;
  cursor: pointer;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: white;
}
.ant-select-focused:where(.css-dev-only-do-not-override-19gw05y).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: white !important;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
  color: white;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-select-single.ant-select-open
  .ant-select-selection-item {
  color: white;
}

:where(.css-dev-only-do-not-override-19gw05y).ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f7f9;
  color: #004a98;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
  letter-spacing: 0.5px;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-select-dropdown
  .ant-select-item {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
  letter-spacing: 0.5px;
  margin-top: 1px;
}

:where(.css-dev-only-do-not-override-1y6mynt).ant-tabs .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: 0.5px;
}

:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr:not(:last-child)
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr:not(:last-child)
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr:not(:last-child)
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr:not(:last-child)
  > th {
  border-bottom: 1px solid #f0f0f0;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
  letter-spacing: 0.5px;
  background: #f5f7f9 !important;
  padding: 14px !important;
}

:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  text-align: start;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
  letter-spacing: 0.5px;
  background: #e5edf5 !important;
  padding: 3px !important;
}

:where(.css-dev-only-do-not-override-1y6mynt).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  position: relative;
  background-color: #e5edf5;
  border: none;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
:where(.css-dev-only-do-not-override-1y6mynt).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector
  .ant-select-focused {
  position: relative;
  background-color: #e5edf5;
  border: none;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
:where(.css-dev-only-do-not-override-1y6mynt).ant-popover .ant-popover-title {
  min-width: 177px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.88);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
  letter-spacing: 0.5px;
}
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper tfoot > tr > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  tfoot
  > tr
  > td {
  position: relative;
  padding: 5px 16px;
  overflow-wrap: break-word;
}
:where(.css-dev-only-do-not-override-1y6mynt).ant-modal .ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.88);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
  letter-spacing: 0.5px;
  word-wrap: break-word;
}
/* :where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper table {
  width: 100%;
  text-align: start;
  border-radius: 8px 8px 8px 8px;
  border-collapse: separate;
  border-spacing: 5px;
} */
.highlighted-row {
  /* background-color: #ffeeba; */
  font-weight: bold;
}
/* :where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > td,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > td,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > td,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > td,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > td,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > th,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > td,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td,
:where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > td {
  border-inline-end: 1px solid #f0f0f0;
  background: bisque;
} */
/* :where(.css-dev-only-do-not-override-1y6mynt).ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td {
  background: bisque;
} */
:where(
    .css-dev-only-do-not-override-1y6mynt
  ).ant-select-single.ant-select-lg.ant-select-show-arrow
  .ant-select-selection-item,
:where(
    .css-dev-only-do-not-override-1y6mynt
  ).ant-select-single.ant-select-lg.ant-select-show-arrow
  .ant-select-selection-placeholder {
  padding-inline-end: 25px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 129%; */
  letter-spacing: 0.5px;
}

:where(.css-dev-only-do-not-override-1y6mynt).ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 0;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Roboto;
  display: inline-block;
  width: 100%;
}
